import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box, Flex, Link, Heading, Text } from 'rebass/styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { updatePost, generatePostSlug } from '../../models/Posts'
import PageBuilderHeader from '../../modules/PageBuilderHeader'
import PageBuilder from '../../modules/PageBuilder'
import {
    setPBDoc,
    setPageDetails,
    setPageExtras,
    setPageMedia,
    setModules,
} from '../../system/redux/reducers/pagebuilder'
import {
    saveToggle,
    savingToggle,
} from '../../system/redux/reducers/savehandling'
import EditableField from '../../components/forms/EditableField'
import ModuleSelectBox from '../../components/pagebuilder/ModuleSelectBox'
import BorderContainer from '../../components/general/BorderContainer'
import PageLoader from '../../components/loaders/PageLoader'
import Gallery from '../../modules/Gallery'
import loadable from '@loadable/component'
import Editor from '../../components/general/Editor'
import TemplateSelect from '../../components/selects/TemplateSelect'

import { onSnapshot, doc } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const RightSideBar = loadable(() => import('../../modules/RightSideBar'))
const ModuleSettings = loadable(() => import('../../modules/ModuleSettings'))

const PostsModify = ({
    dispatch,
    id,
    auth,
    pageDetails,
    pageDetailExtras,
    pageMedia,
    pageModules,
    isSaving,
    isSetting,
    templates,
}) => {
    const [templateSelect, setTemplates] = useState({ setTemplate: true })
    const { register, errors, handleSubmit } = useForm()
    const [post, setPost] = useState(false)

    useEffect(() => {
        let isCancelled = false
        onSnapshot(doc(db, 'posts', id), (doc) => {
            if (!isCancelled) {
                setPost(doc.data())
            }
        })

        return () => {
            isCancelled = true
        }
    }, [id])
    useEffect(() => {
        if (post) {
            const item = { ...post }
            let spreadModules = []
            if (
                item.content &&
                item.content.modules &&
                item.content.modules.length
            ) {
                spreadModules = !Array.isArray(item.content.modules)
                    ? Object.values(item.content.modules)
                    : [...item.content.modules]
            }

            let published = false
            if (item.date_published) {
                published = {
                    date_published: item.date_published,
                    published_by: item.published_by,
                }
            }
            let updated = false
            if (item.date_modified) {
                updated = {
                    date_modified: item.date_modified,
                    edited_by: item.edited_by,
                }
            }
            dispatch(setPBDoc(id))
            const extrasData = JSON.stringify({
                description: item.content.description || '',
                role: item.content.role || '',
            })
            dispatch(setPageExtras(extrasData))

            const detailsData = JSON.stringify({
                added_by: item.added_by || '',
                categories: item.categories || [],
                excerpt: item.content.excerpt || '',
                parent: item.parent || '',
                published,
                slug: item.slug || '',
                role: item.content.role || '',
                type: item.content.type || '',
                tags: item.tags || [],
                title: item.title || '',
                updated,
                visibility: item.visibility || 'draft',
            })
            dispatch(setPageDetails(detailsData))
            const mediaData = JSON.stringify({
                galleryDoc: (item.media && item.media.gallery.docs) || [],
                galleryImage: (item.media && item.media.gallery.images) || [],
                galleryVideo: (item.media && item.media.gallery.videos) || [],
                mediaSet: (item.media && item.media.set) || [],
            })
            dispatch(setPageMedia(mediaData))
            dispatch(setModules(spreadModules))

            if (!spreadModules.length) {
                setTemplates({ setTemplate: true })
            }
        }
    }, [post])

    useEffect(() => {
        if (pageModules.length) {
            setTemplates({ setTemplate: false })
        }
    }, [pageModules])

    const onInputChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.target.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const onEditorChange = (value) => {
        const extrasData = JSON.stringify({ description: value })
        dispatch(setPageExtras(extrasData))
        dispatch(saveToggle(true))
    }

    const constructPermalink = () => {
        let url = process.env.GATSBY_WEB_ADDRESS

        if (pageDetails.parent) {
            url += '/' + pageDetails.parent
        }

        return (url += '/')
    }

    const onSubmit = async () => {
        let updateDetails = { ...pageDetails }
        let updateMedia = { ...pageMedia }
        let updateExtras = { ...pageDetailExtras }
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))

        const save = {
            added_by: updateDetails.added_by,
            parent: updateDetails.parent,
            title: updateDetails.title,
            slug: updateDetails.slug,
            visibility: updateDetails.visibility,
            media: {
                gallery: {
                    images: updateMedia.galleryImage,
                    docs: updateMedia.galleryDoc,
                    videos: updateMedia.galleryVideo,
                },
                set: updateMedia.mediaSet,
            },
            content: {
                ...post.content,
                description: updateExtras.description,
                excerpt: updateDetails.excerpt,
                role: updateDetails.role,
                modules: pageModules,
            },
            categories: updateDetails.categories,
            tags: updateDetails.tags,
        }

        if (post.slug !== save.slug) {
            save.slug = await generatePostSlug(save.slug)
        }

        // We are updating an already existing page, and we're moving from draft to non-draft - so add published details
        if (
            post.visibility === 'draft' &&
            updateDetails.visibility !== 'draft'
        ) {
            save.published_by = auth.uid
            save.date_published = true // handled by firebase
        }

        // if we're draft, always remove published details
        if (updateDetails.visibility === 'draft') {
            save.published_by = null
            save.date_published = null
        } else if (updateDetails?.published?.date_published) {
            // otherwise, we might need to update the published date (if one exists)
            save.date_published = updateDetails.published.date_published
        }

        // We're updating all post data
        updatePost(id, save).then(() => {
            dispatch(savingToggle(false))
        })
    }
    return (
        <Box>
            <Flex alignItems='center' justifyContent='flex-start'>
                <Heading variant='h4' as='h1' mr='lg' flexGrow={1}>
                    <EditableField
                        name='title'
                        placeholder='Enter Title'
                        {...register('title', { required: true })}
                        type='text'
                        value={pageDetails.title}
                        variant='contentEditableInput'
                        onChange={(e) => onInputChange('title', e)}
                        pl='0'
                        errors={errors}
                        errorMessage='Cannot be empty'
                    />
                </Heading>
                {/* <Flex flexGrow={1} height="2px" bg="greyLighter"></Flex> */}
                {/* <Button variant='primarySmall' ml="md">Preview</Button> */}
            </Flex>
            <Box>
                <Flex
                    alignItems='center'
                    justifyContent='flex-start'
                    color='greyMedium'
                    fontSize='md'
                >
                    <Text fontWeight='bold'>Permalink:</Text>
                    <Link
                        pl='xxs'
                        href={
                            constructPermalink() +
                            'features/' +
                            pageDetails.slug +
                            '?preview=true&id=' +
                            id
                        }
                        color='greyMedium'
                        fontSize='lg'
                        target='_blank'
                        sx={{ cursor: 'pointer' }}
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Link>
                    <Flex pl='xxs' mr='xxs' alignItems='center' flexGrow={1}>
                        {constructPermalink() + 'features/'}
                        <EditableField
                            name='slug'
                            placeholder='Enter Slug'
                            {...register('slug', { required: true })}
                            type='text'
                            value={pageDetails.slug || 'Add Slug'}
                            variant='contentEditableInput'
                            onChange={(e) => onInputChange('slug', e)}
                            pl='0'
                            errors={errors}
                            errorMessage='Cannot be empty'
                        />
                    </Flex>
                </Flex>
                <Flex>
                    <Flex flexGrow={1} flex={1} pr='xs' flexDirection='column'>
                        <Box variant='card' mt='sm'>
                            <Box p='xs'>
                                <Heading py='sm' variant='h5' as='h5'>
                                    Description
                                </Heading>
                                <Editor
                                    content={pageDetailExtras.description}
                                    onEditorChange={onEditorChange}
                                />
                            </Box>
                        </Box>
                        <Box variant='card' pt='lg' mt='sm'>
                            <PageBuilderHeader />
                            <Box px='xs'>
                                {templateSelect.setTemplate ? (
                                    <Box
                                        p='xs'
                                        width='100%'
                                        sx={{ position: 'relative' }}
                                    >
                                        <Text py='xs' as='p'>
                                            To start, either select a template:
                                        </Text>
                                        <Flex py='sm' alignItems='center'>
                                            <Box
                                                fontWeight='semibold'
                                                width={1 / 3}
                                            >
                                                Template:
                                            </Box>
                                            <Box width={2 / 3}>
                                                <TemplateSelect />
                                            </Box>
                                        </Flex>
                                        <Text py='xs' as='p'>
                                            or, click the + below and start
                                            building:
                                        </Text>
                                    </Box>
                                ) : null}
                                {!templateSelect.setTemplate ? (
                                    <PageBuilder modules={pageModules} />
                                ) : (
                                    <Box pr='xs' width='100%'>
                                        <BorderContainer>
                                            <ModuleSelectBox
                                                index='0'
                                                moduleKey='0'
                                            />
                                        </BorderContainer>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box variant='card' mt='sm'>
                            <Gallery
                                title='Image Gallery'
                                media={pageMedia?.galleryImage}
                                type='images'
                            />
                        </Box>

                        <Box variant='card' mt='sm'>
                            <Gallery
                                title='Video Gallery'
                                media={pageMedia?.galleryVideo}
                                type='videos'
                            />
                        </Box>
                        <Box variant='card' mt='sm'>
                            <Gallery
                                title='Docs'
                                media={pageMedia?.galleryDocs}
                                type='docs'
                            />
                        </Box>
                    </Flex>
                    {isSetting ? (
                        <ModuleSettings />
                    ) : (
                        <RightSideBar
                            onInputChange={onInputChange}
                            onSubmit={onSubmit}
                            section='posts'
                        />
                    )}
                </Flex>
            </Box>
            {isSaving ? <PageLoader /> : null}
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        pageModules: state.Pagebuilder.pagebuilder.modules,
        pageDetails: state.Pagebuilder.details,
        pageDetailExtras: state.Pagebuilder.extras,
        pageMedia: state.Pagebuilder.media,
        auth: state.Auth.userVerified,
        isSaving: state.Savehandling.isSaving,
        isSetting: state.Sidebar.isSetting,
    }
}
export default connect(mapStateToProps)(PostsModify)
