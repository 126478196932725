import { saveToggle } from '../system/redux/reducers/savehandling'
import { setPageMedia } from '../system/redux/reducers/pagebuilder'

export const onGalleryChange = (dispatch, array, target) => {
    let gallery = ''
    switch (target) {
    case 'docs':
        gallery = 'galleryDoc'
        break;
    case 'videos':
        gallery = 'galleryVideo'
        break;
    case 'images':
        gallery = 'galleryImage'
        break;
    default:
        gallery = 'mediaSet'
        break;
    }

    const mediaData = JSON.stringify({ [gallery]: array })
    dispatch(setPageMedia(mediaData))
    dispatch(saveToggle(true))
}