import React from 'react'
import { Box, Heading } from 'rebass/styled-components'
import { updatePostTimestamp, transferLegacyPosts, convertLegacyTagsToCRMFormat } from '../../models/Posts'

const CollectionView = ({ dispatch }) => {
    const sendUpdatePostTimestamp = () => {
        updatePostTimestamp()
    }

    return (
        <Box variant="card" pt="lg" mt="sm">
            <Box mb='md' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Box px='xs' pb='xs'>
                    <Heading as="h2" variant="h4" color="greyMedium">Posts DB Functions</Heading>
                </Box>
            </Box>

            <Box onClick={sendUpdatePostTimestamp}>updatePostTimestamp</Box>
            <Box onClick={transferLegacyPosts}>transferLegacyPosts</Box>
            <Box mt={2} onClick={convertLegacyTagsToCRMFormat}>convertLegacyTagsToCRMFormat</Box>
        </Box>
    )
}

export default CollectionView
