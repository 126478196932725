import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Text, Box } from 'rebass/styled-components'
import Moment from 'react-moment'
import { navigate, Link } from 'gatsby'

import Table from '../../components/tables/Table'
import TableCheckBox from '../../components/tables/TableCheckBox'
import TableSort from '../../components/tables/TableSort'
import { sortTable } from '../../system/redux/reducers/tables'
import Cell from '../../components/tables/Cell'
import Row from '../../components/tables/Row'
import EllipsisControls from '../../components/tables/EllipsisControls'
import EllipsisControlsItems from '../../components/tables/EllipsisControlsItems'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import { setList, resetList } from '../../system/redux/reducers/list'
import { clearFilter } from '../../system/redux/reducers/filter'
import { resetTable } from '../../system/redux/reducers/tables'
import { getPosts, duplicateItem } from '../../models/Posts'

import { onSnapshot, collection, orderBy, query, where, getDocs,Timestamp } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import Loading from '../../components/general/Loading'
import InfiniteScroll from 'react-infinite-scroll-component'

const PostsView = ({ dispatch, order, facetfilter, lastVisible, list, filter, term }) => {
    const ID = 'POST'
    const [allChecked, checkAll] = useState(false)
    const [isAscending, setAscending] = useState(true)
    const [sortColumn, setSortColumn] = useState('date_added')
    const template = '0.7fr 2fr 1fr 1.5fr 1.5fr 1.5fr 1fr 0.3fr'
    const [posts, setPosts] = useState(false)
    const [users, setUsers] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let isCancelled = false
        const getUsers = async () => {
            if (!isCancelled) {
                const output = []
                const querySnapshot = await getDocs(collection(db, 'users'));
                querySnapshot.forEach((doc) => {
                    output.push(doc.data())
                })
                setUsers(output)
            }
        }
        getUsers()
        dispatch(resetTable())
        dispatch(clearFilter())
        dispatch(resetList())
        return () => {
            isCancelled = true
        }
    }, [])

    useEffect(() => {
        if (!lastVisible && filter && !filter.length) {
            fetchMoreData({ first: true })
        }
    }, [lastVisible, filter])

    useEffect(() => {
        setLoading(true)
        fetchMoreData({ first: true })
    }, [posts])

    useEffect(() => {
        let isCancelled = false
        const q = facetfilter.category ? query(collection(db, 'posts'), where(facetfilter.criteria, 'array-contains', facetfilter.category), orderBy((order[ID] ? order[ID].column : 'date_added'), (order[ID] ? order[ID].direction : 'asc'))) :  query(collection(db, 'posts'), orderBy((order[ID] ? order[ID].column : 'date_added'), (order[ID] ? order[ID].direction : 'desc')))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const docData = doc.data()
                    docData.id = doc.id
                    output.push(docData)
                })
                setPosts(output)
            }
        })

        return () => {
            isCancelled = true
        }
    }, [])


    // useEffect(() => {
    //     setLoading(true)
    //     fetchMoreData({ first: true })
    // }, [posts, facetfilter, order])

    // useEffect(() => {
    //     let isCancelled = false
    //     const q = facetfilter.category ? query(collection(db, 'posts'), where(facetfilter.criteria, 'array-contains', facetfilter.category), orderBy((order[ID] ? order[ID].column : 'date_added'), (order[ID] ? order[ID].direction : 'asc'))) :  query(collection(db, 'posts'), orderBy((order[ID] ? order[ID].column : 'date_added'), (order[ID] ? order[ID].direction : 'desc')))
    //     onSnapshot(q, (snapshot) => {
    //         if (!isCancelled) {
    //             const output = []
    //             snapshot.forEach((doc) => {
    //                 const docData = doc.data()
    //                 docData.id = doc.id
    //                 output.push(docData)
    //             })
    //             setPosts(output)
    //         }
    //     })

    //     return () => {
    //         isCancelled = true
    //     }
    // }, [facetfilter, order])

    const areYouSure = (id) => {
        const confirmData = JSON.stringify({ isOpen: true, id, modalType: 'DELETE_POST', collection: null })
        dispatch(confirmBoxToggle(confirmData))
    }
    const goToEdit = (id) => {
        navigate(`/posts/edit/${id}`)
    }

    const addNew = () => {
        navigate(`/posts/add/`)
    }
    const sortByColumn = (column) => {
        setSortColumn(column)
        setAscending(!isAscending)

        const sortData = JSON.stringify({
            column,
            table: ID,
            direction: isAscending ? 'desc' : 'asc',
        })
        dispatch(sortTable(sortData))
    }

    const duplicateThisItem = async (id) => {
        setLoading(true)
        duplicateItem(id)
    }

    const fetchMoreData = async ({ first = false, lastVisible, filter, term }) => {
        const perPage = 15
        let count = list.length / perPage
        let page = count === 0 ? count : count + 1
        let items = [...list]
        let last = lastVisible
        
        if (first) {
            count = perPage
            page = 0
            items = []
            last = false
        }

        const results = await getPosts({ page, limitNumber: perPage, lastVisible: last, order: order[ID], facetfilter, filter, term })
        if (results.list) {
            Object.entries(results.list).map(([key, item]) => {
                items.push(item)
                return true
            })
            const listData = JSON.stringify({
                list: items,
                lastVisible: results.lastVisible,
                filter: results.filter,
                term
            })
            dispatch(setList(listData))
            setLoading(false)

        }
    }
    const headerRow = [
        <Cell key="checkbox" className="center" justifyContent="center">
            <TableCheckBox onPress={() => checkAll(!allChecked)} />
        </Cell>,
        <Cell key="title" onClick={ () => sortByColumn('title') }>
            <Text>Title</Text>
            { sortColumn === 'title' ? <TableSort direction={ isAscending } /> : null }
        </Cell>,
        <Cell key="added_by" onClick={ () => sortByColumn('added_by') }><Text>Author</Text>{ sortColumn === 'added_by' ? <TableSort direction={ isAscending } /> : null } </Cell>,
        <Cell key="categories" onClick={ () => sortByColumn('categories') }><Text>Category</Text>{ sortColumn === 'categories' ? <TableSort direction={ isAscending } /> : null }</Cell>,
        <Cell key="date_added" onClick={ () => sortByColumn('date_added') }><Text>Created</Text>{ sortColumn === 'date_added' ? <TableSort direction={ isAscending } /> : null }</Cell>,
        <Cell key="date_modified" onClick={ () => sortByColumn('date_modified') }><Text>Modified</Text>{ sortColumn === 'date_modified' ? <TableSort direction={ isAscending } /> : null }</Cell>,
        <Cell key="visibility" onClick={ () => sortByColumn('visibility') }><Text>Status</Text>{ sortColumn === 'visibility' ? <TableSort direction={ isAscending } /> : null }</Cell>,
        <Cell key="actions"></Cell>,
    ]
    
    return (
        <Table title="All Posts" ID={ID} contentType="POST" addNew={addNew} template={template} headerRow={headerRow} facetfilter={true} algoliaIndice={process.env.GATSBY_ALGOLIA_POST_INDICE} fetchMoreData={fetchMoreData}>
            {
                loading ?
                    <Box p='xs'><Loading /></Box> :
                    <InfiniteScroll
                        style={{ overflow: 'initial' }}
                        dataLength={list.length}
                        next={() => fetchMoreData({ first: false, lastVisible, term })}
                        hasMore={posts.length > list.length && !filter?.length ? true : false}
                        loader={posts.length > list.length && !filter?.length ? <Box p='xs'><Loading /></Box> : null}
                    >
                        { users &&
                            Object.entries(list).map(([key, post]) => {
                                const author = users.filter(user => user.uid === post.added_by)[0]
                                return (
                                    <Row key={key + '_' + post.id} py='sm' template={template}>
                                        <Cell className="center">
                                            <TableCheckBox checked={allChecked} doc={post.id} table={ID} />
                                        </Cell>
                                        <Cell fontWeight="bold">
                                            <Link to={`/posts/edit/${post.id}`}>
                                                {post.title }
                                            </Link>
                                        </Cell>
                                        <Cell>
                                            {author?.first_name + ' ' + author?.last_name}
                                        </Cell>
                                        <Cell>
                                            <Box>
                                                { post.categories ? post.categories.map((cat, catKey) =>{
                                                    return <Text key={catKey + '_cat'} sx={{ textTransform:'capitalize' }}>{cat.replaceAll('-',' ')}</Text>
                                                }) : null }
                                            </Box>
                                        </Cell>
                                        <Cell>
                                            <Moment format="YYYY-MM-DD HH:mm" date={new Timestamp(post.date_added.seconds, post.date_added.nanoseconds).toDate()} />
                                        </Cell>
                                        <Cell>
                                            <Moment format="YYYY-MM-DD HH:mm" date={new Timestamp(post.date_modified.seconds, post.date_modified.nanoseconds).toDate()} />
                                        </Cell>
                                        <Cell>
                                            <Text sx={{ textTransform:'capitalize' }}>
                                                {post.visibility}
                                            </Text>
                                        </Cell>
                                        <Cell className="end">
                                            <Box>
                                                <EllipsisControls>
                                                    <EllipsisControlsItems onClick={() => duplicateThisItem(post.id)}>Duplicate</EllipsisControlsItems>
                                                    <EllipsisControlsItems onClick={() => goToEdit(post.id)}>Edit</EllipsisControlsItems>
                                                    <EllipsisControlsItems onClick={() => areYouSure(post.id)}>Delete</EllipsisControlsItems>
                                                </EllipsisControls>
                                            </Box>
                                        </Cell> 
                                    </Row>
                                )
                            })
                        }
                    </InfiniteScroll>
            }
        </Table>
    )
}

function mapStateToProps(state) {
    return {
        order: state.Tables.sort,
        facetfilter: state.Filter,
        list: state.List.list,
        refresh: state.List.refresh,
        lastVisible: state.List.lastVisible,
        filter: state.List.filter,
        term: state.List.term,
    }
}

export default connect(mapStateToProps)(PostsView)
