import React from 'react'
import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import { PostsSection } from '../system/settings/pageData'
import PostsEditView from '../views/posts/modify'
import PostsAddView from '../views/posts/add'
import PostsView from '../views/posts/index'
import PostCategoriesView from '../views/posts/categories'
import PostsFunctionsView from '../views/posts/functions'
import Pointer from '../views/pointer'
import { connect } from 'react-redux';


const PageInfo = {
    parent: PostsSection.section,
    page: 'Add New',
    icon: PostsSection.icon,
}

const PostRoutes = ({ menuItems }) => {
    return (
        <Router>
            <Pointer PageInfo={{ ...PageInfo, page: 'Functions' }} Component={PostsFunctionsView} minimumRole={PostsSection.minimumRole} path={withPrefix('/posts/functions')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Edit' }} Component={PostsEditView} minimumRole={PostsSection.minimumRole} path={withPrefix('/posts/edit/:id')} />
            <Pointer PageInfo={PageInfo} Title='Add New' Component={PostsAddView} minimumRole={PostsSection.minimumRole} path={withPrefix('/posts/add')} id='' />
            <Pointer PageInfo={{ ...PageInfo, page: 'Categories' }} Title='Categories' Component={PostCategoriesView} minimumRole={PostsSection.minimumRole} path={withPrefix('/posts/categories')} id='' />
            <Pointer PageInfo={{ ...PageInfo, page: 'All Posts' }} Component={PostsView} minimumRole={PostsSection.minimumRole} path={withPrefix('/posts')} />
            {/* {menuItems && menuItems.length && 
                menuItems.map((item, index) =>{
                    return <Pointer key={index} PageInfo={{ ...PageInfo, page: item.category }} Component={PostsView} minimumRole={PostsSection.minimumRole} path={withPrefix(`/posts/${item.slug}`)} category={item.slug}/>
                })
            } */}
        </Router>
    );
}

function mapStateToProps(state) {
    return {
        menuItems: state.Sidebar.menuItems.posts,
    };
}
export default connect(mapStateToProps)(PostRoutes)
