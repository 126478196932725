import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'
import { addPost } from '../../models/Posts'
import PageBuilderHeader from '../../modules/PageBuilderHeader'
import PageBuilder from '../../modules/PageBuilder'
import {
    setPageDetails,
    resetPageBuilder,
} from '../../system/redux/reducers/pagebuilder'
import {
    saveToggle,
    savingToggle,
} from '../../system/redux/reducers/savehandling'
import EditableField from '../../components/forms/EditableField'
import ModuleSelectBox from '../../components/pagebuilder/ModuleSelectBox'
import BorderContainer from '../../components/general/BorderContainer'
import PageLoader from '../../components/loaders/PageLoader'
import loadable from '@loadable/component'
import TemplateSelect from '../../components/selects/TemplateSelect'
import Gallery from '../../modules/Gallery'

const RightSideBar = loadable(() => import('../../modules/RightSideBar'))
const ModuleSettings = loadable(() => import('../../modules/ModuleSettings'))

const PostsAddView = ({
    dispatch,
    auth,
    author,
    pageDetails,
    pageDetailExtras,
    pageMedia,
    pageModules,
    isSaving,
    type,
    isSetting,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [templateSelect, setTemplates] = useState({ setTemplate: true })
    useEffect(() => {
        dispatch(resetPageBuilder())
    }, [])

    useEffect(() => {
        setTemplates({ setTemplate: pageModules.length ? false : true })
    }, [pageModules])

    const onInputChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.target.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const onSubmit = async () => {
        let updateDetails = { ...pageDetails }
        let updateMedia = { ...pageMedia }
        let updateExtras = { ...pageDetailExtras }
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))

        const save = {
            parent: '',
            title: updateDetails.title,
            visibility: updateDetails.visibility,
            media: {
                gallery: {
                    images: updateMedia.galleryImage,
                    docs: updateMedia.galleryDoc,
                    videos: updateMedia.galleryVideo,
                },
                set: updateMedia.mediaSet,
            },
            content: {
                description: updateExtras.description,
                excerpt: updateDetails.excerpt,
                modules: pageModules,
                role: updateDetails.role,
                type: type,
            },
            categories: updateDetails.categories,
            tags: updateDetails.tags,
            added_by: author || auth.uid,
        }

        // We are updating an already existing page
        if (updateDetails.visibility !== 'draft') {
            save.published_by = auth.uid
            save.date_published = true
        }

        addPost(save).then((docRef) => {
            navigate(`/posts/edit/${docRef.data}`)
            dispatch(savingToggle(false))
        })
    }
    return (
        <Box
            as='form'
            onSubmit={handleSubmit(() => {
                onSubmit()
            })}
        >
            <Flex alignItems='center' justifyContent='flex-start'>
                <Heading variant='h4' as='h1' mr='lg'>
                    <EditableField
                        name='title'
                        placeholder='Enter Title'
                        {...register('title', { required: true })}
                        type='text'
                        value={pageDetails.title}
                        variant='contentEditableInput'
                        onChange={(e) => onInputChange('title', e)}
                        pl='0'
                        errors={errors}
                        errorMessage='Cannot be empty'
                    />
                </Heading>
                <Flex flexGrow={1} height='2px' bg='greyLighter'></Flex>
                {/* <Button variant='primarySmall' ml="md">Preview</Button> */}
            </Flex>
            <Box>
                <Flex>
                    <Flex flexGrow={1} flex={1} pr='xs' flexDirection='column'>
                        <Box variant='card' pt='lg' mt='sm'>
                            <PageBuilderHeader />
                            <Box px='xs'>
                                {templateSelect.setTemplate ? (
                                    <Box
                                        p='xs'
                                        width='100%'
                                        sx={{ position: 'relative' }}
                                    >
                                        <Text py='xs' as='p'>
                                            To start, either select a template:
                                        </Text>
                                        <Flex py='sm' alignItems='center'>
                                            <Box
                                                fontWeight='semibold'
                                                width={1 / 3}
                                            >
                                                Template:
                                            </Box>
                                            <Box width={2 / 3}>
                                                <TemplateSelect />
                                            </Box>
                                        </Flex>
                                        <Text py='xs' as='p'>
                                            or, click the + below and start
                                            building:
                                        </Text>
                                    </Box>
                                ) : null}
                                {!templateSelect.setTemplate ? (
                                    <PageBuilder modules={pageModules} />
                                ) : (
                                    <Box pr='xs' width='100%'>
                                        <BorderContainer>
                                            <ModuleSelectBox
                                                index='0'
                                                moduleKey='0'
                                            />
                                        </BorderContainer>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box variant='card' mt='sm'>
                            <Gallery
                                title='Image Gallery'
                                media={pageMedia?.galleryImage}
                                type='images'
                            />
                        </Box>

                        <Box variant='card' mt='sm'>
                            <Gallery
                                title='Video Gallery'
                                media={pageMedia?.galleryVideo}
                                type='videos'
                            />
                        </Box>
                        <Box variant='card' mt='sm'>
                            <Gallery
                                title='Docs'
                                media={pageMedia?.galleryDocs}
                                type='docs'
                            />
                        </Box>
                    </Flex>
                    {isSetting ? (
                        <ModuleSettings />
                    ) : (
                        <RightSideBar
                            onInputChange={onInputChange}
                            section='posts'
                        />
                    )}
                </Flex>
            </Box>
            {isSaving ? <PageLoader /> : null}
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        pageModules: state.Pagebuilder.pagebuilder.modules,
        pageDetails: state.Pagebuilder.details,
        pageDetailExtras: state.Pagebuilder.extras,
        pageMedia: state.Pagebuilder.media,
        auth: state.Auth.userVerified,
        author: state.Pagebuilder.details.added_by,
        type: state.Pagebuilder.details.type,
        isSaving: state.Savehandling.isSaving,
        isSetting: state.Sidebar.isSetting,
    }
}
export default connect(mapStateToProps)(PostsAddView)
