import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Flex, Text } from 'rebass/styled-components'

import Table from '../../components/tables/Table'
// import TableCheckBox from '../../components/tables/TableCheckBox'
// import TableSort from '../../components/tables/TableSort'
// import { sortTable } from '../../system/redux/reducers/tables'
import Cell from '../../components/tables/Cell'
import RowCategories from '../../components/tables/RowCategories'

import { onSnapshot, collection, orderBy, query } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const PostsCategoriesView = ({ dispatch, order }) => {
    const ID='TABLE_POST_CAT'
    const [itemList, updateList] = useState(false)
    // const [allChecked, checkAll] = useState(false)
    // const sortDirection = order[ID] ? order[ID].direction : false
    // const sortColumn = order[ID] ? order[ID].col : false
    const template = '0.7fr 2fr 1.5fr 1.5fr 1fr 0.5fr'
    const [rows, setRows] = useState([])

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'postCategories'), orderBy((order[ID] ? order[ID].column : 'category'), (order[ID] ? order[ID].direction : 'asc')))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const catData = doc.data()
                    catData.id = doc.id
                    output.push(catData)
                })
                updateList(output)
            }
        })

        return () => {
            isCancelled = true
        }
    }, [])

    const addNew = () => {
        const cats = [...itemList]
        const newCategory = {
            title: '',
            added_by: '',
            content: {
                categories: '',
            },
            date_added: '',
            date_modified: '',
            menu: false,
        }
        cats.unshift(newCategory)
        updateList(cats)
    }

    const removeNew = (index) => {
        const filtered = [...itemList]
        const filteredItems = filtered.slice(0, index).concat(itemList.slice(index + 1, itemList.length))
        updateList(filteredItems)
    }

    useEffect(() => {
        if (itemList) {
            const itemRow = []
            Object.entries(itemList).map(([key, category]) => {
                itemRow.push(
                    <RowCategories key={key + '_' + category.id} ID={ID} template={template} doc={key} category={category} checked={category.menu} source='posts' removeNew={removeNew} />,
                )
                return true
            })
            setRows(itemRow)
        }
    }, [itemList])

    const headerRow = [
        <Cell key="checkbox" />,
        <Cell key="category"><Text>Category</Text></Cell>,
        <Cell key="description"><Text>Description</Text></Cell>,
        <Cell className="center" key="admin_menu" justifyContent="center">
            Admin Menu
        </Cell>,
        <Cell key="slug"><Text>Slug</Text></Cell>,
        <Cell key="actions"></Cell>,
    ]


    return (
        <Table title="All Categories" ID={ID} contentType="POST_CAT" addNew={addNew} template={template} headerRow={headerRow} actions={false}>
            { rows.length ? rows : <Flex justifyContent='center' alignItems='center' pt='md'>No Categories exist yet.</Flex> }
        </Table>
    )
}

function mapStateToProps(state) {
    return {
        order: state.Tables.sort,
    }
}

export default connect(mapStateToProps)(PostsCategoriesView)
